import React from 'react'

import countries from 'utils/countries'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Box, Text, Image } from 'rebass'

import { useLocation } from 'context/location'
import loadable from '@loadable/component'
const Select = loadable(() => import('components/Forms/BambusSelect'))

const formatOptionLabel = ({ label, value, icon }) => (
  <Box
    sx={{
      display: 'flex',
    }}
  >
    <Box
      sx={{
        flex: 1,
      }}
    >
      <Image
        src={findFlagUrlByIso2Code(icon)}
        alt={icon}
        sx={{
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '22px',
          borderRadius: '1px',
          marginRight: '10px',
          marginTop: '-4px',
        }}
      />
      <span className="react-select-option--hide-in-single">{label}</span>
      <span className="react-select-option--hide-in-menu">{label}</span>
    </Box>

    <Text
      sx={{
        marginLeft: '0px',
        color: 'rgba(1, 42, 89, 0.5)',
      }}
    >
      <span className="react-select-option--hide-in-single">{value}</span>
    </Text>
  </Box>
)

const LocationSelect = React.forwardRef(
  (
    { value, onChange, countryCodesToShow = null, noLabel = false, ...props },
    ref
  ) => {
    const { countryCode, setCountryCode } = useLocation()
    const [useDefaultCountryCode, setUseDefaultCountryCode] =
      React.useState(true)

    const options = React.useMemo(() => {
      const mappingFunction = (c) => ({
        icon: c.code.toLowerCase(),
        value: c.code,
        label: c.name,
        prefix: c.prefix,
      })
      if (Array.isArray(countryCodesToShow)) {
        return countries
          .filter((c) => countryCodesToShow.includes(c.code))
          .map(mappingFunction)
      } else {
        return countries.map(mappingFunction)
      }
    }, [countryCodesToShow])

    // Initially default countryCode to AT if anything else than DE is set
    // Then, if user change countryCode disabled default countryCode
    const countryCodeWithFallback = useDefaultCountryCode
      ? countryCode !== 'DE'
        ? 'AT'
        : countryCode
      : value ?? countryCode

    const selectedOption = options.find(
      (option) => option.value === countryCodeWithFallback
    )

    return (
      <Select
        {...(!noLabel && {
          label: 'Meine Immobilie befindet sich in',
        })}
        defaultValue={selectedOption}
        value={selectedOption}
        options={options}
        formatOptionLabel={formatOptionLabel}
        onChange={(option) => {
          // Only transition globally (application country)
          // if country code is either DE or AT.
          // On the contrary, stay as is.
          const countryCodeToTransitionToGlobally =
            option.value !== 'DE' && option.value !== 'AT'
              ? countryCode
              : option.value
          setUseDefaultCountryCode(false)
          setCountryCode(countryCodeToTransitionToGlobally)
          onChange(option)
        }}
        inputRef={ref}
        {...props}
      />
    )
  }
)

export default LocationSelect
